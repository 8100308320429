import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/404.scss"

const NotFoundPage = () => (

  <Layout>

    <SEO title="404: Not found" />
    <Link to="/" className="logo" activeClassName="-active">Super!com</Link>

    <section className="page-404">
      <h1 className="page-404__title">NOT FOUND</h1>
      <p className="page-404__message">You just hit a route that doesn&#39;t exist... the sadness.</p>
    </section>

  </Layout>

)

export default NotFoundPage
